import { Tooltip } from '@mantine/core'
import { VideoInputOutputDTO } from '../../models/video-input-output-dto';

type TVideoBadge = {
  videoData: VideoInputOutputDTO
  zIndex?: number
  type?: 'servico' | 'alerta' | 'padrao'
  onClickBadge: () => void;
  onClickBullet: (start?: number) => void;
}

export function VideoBadge({
  videoData,
  zIndex,
  type = 'padrao',
  onClickBadge,
  onClickBullet
}: TVideoBadge) {

  const [hourVideoStart, minutesVideoStart] = videoData.inicioDoVideo.split(':')
  const videoStart = [hourVideoStart, minutesVideoStart].join(':')
  const [hourVideoEnd, minutesVideoEnd] = videoData.fimDoVideo.split(':')
  const videoEnd = [hourVideoEnd, minutesVideoEnd].join(':')

  const startMinutes = (parseInt(hourVideoStart) * 60) + parseInt(minutesVideoStart);
  const endMinutes = (parseInt(hourVideoEnd) * 60) + parseInt(minutesVideoEnd);
  const diffMinutes = endMinutes - startMinutes;

  const rawWidth = (diffMinutes / 60) * 100;
  const width = `${(Math.ceil(rawWidth / 25) * 25) || 25}%`;
  const rawXPos = (parseInt(minutesVideoStart) / 60) * 100;
  const xPosition = rawXPos % 25 === 0 ? `${rawXPos}%` : `${(Math.ceil(rawXPos / 25) - 1) * 25}%`;

  return (
    <div>
      <Tooltip label={`${videoStart} - ${videoEnd}`}>
        <button
          className="min-w-2/4 h-[1rem] absolute top-1/2 -translate-y-1/2"
          style={{
            zIndex: zIndex || 100,
            backgroundColor: '#2E3454',
            width: width,
            left: xPosition,
          }}
          onClick={onClickBadge}
        />
      </Tooltip>
      {type === 'alerta' && (
        <Tooltip label="Alerta">
          <button
            className="w-2 h-2 rounded-full absolute top-1/2 -translate-y-1/2 translate-x-[3px] bg-amber-400 hover:ring-2 ring-amber-400/80"
            style={{
              zIndex: zIndex ? zIndex + 100 : 100,
              left: xPosition,
            }}
            onClick={() => {
              onClickBullet(videoData?.inicioDoAlerta);
              onClickBadge();
            }}
          />
        </Tooltip>
      )}
      {type === 'servico' && (
        <Tooltip label="Serviço">
          <button
            className="w-2 h-2 rounded-full absolute top-1/2 -translate-y-1/2 translate-x-[3px] bg-green-400 hover:ring-2 ring-green-400/80"
            style={{
              zIndex: zIndex ? zIndex + 100 : 100,
              left: xPosition,
            }}
            onClick={() => {
              onClickBullet(videoData?.inicioDoServico);
              onClickBadge();
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}
