import { Table, Text, keys } from '@mantine/core';
import { TableScrollContainerProps } from '@mantine/core/lib/components/Table/TableScrollContainer'
import { VehiclesTableRows } from './vehicles-table-rows'
import { TFilterVehicle } from '@services/hooks/use-filter-vehicles'
import { Th } from '@components/table-sort'
import { useEffect, useState } from 'react'

type TVehiclesTable = {
  vehicles: TFilterVehicle[]
  onSelectVehicle: (vehicle: TFilterVehicle) => void
  selectedVehicle?: boolean
} & Omit<TableScrollContainerProps, 'minWidth'>

export function VehiclesTable({
  vehicles,
  onSelectVehicle,
  selectedVehicle,
  ...props
}: TVehiclesTable) {

  const [sortBy, setSortBy] = useState<string>();
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [sortedData, setSortedData] = useState(vehicles);

  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    console.log('field', field)
    setSortedData(sortData(vehicles, { sortBy: field, reversed }));
  };

  function filterData(data: TFilterVehicle[]) {
    console.log('### filterData :: data:', data);

    return data.filter((item: any) =>
      keys(data[0]).some((key) => item[key])
    );
  }

  function sortData(
    data: any,
    payload: { sortBy: any; reversed: boolean }
  ) {
    const { sortBy } = payload;

    if (!sortBy) {
      return filterData(data);
    }

    return filterData(
      [...data].sort((a, b) => {
        if (payload.reversed) {
          return typeof (b[sortBy]) === 'string'
            ? b[sortBy].localeCompare(a[sortBy])
            : b[sortBy] - (a[sortBy]);
        }

        return typeof (b[sortBy]) === 'string'
          ? a[sortBy].localeCompare(b[sortBy])
          : a[sortBy] - (b[sortBy]);
      }),
    );
  }


  useEffect(() => {
    vehicles && setSortedData(vehicles)
  }, [vehicles])

  return (
    <Table.ScrollContainer minWidth={800} className="w-full p-8 " {...props}>
      <Table verticalSpacing="xs" highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <Th
              sorted={sortBy === 'localidade'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('localidade')}
            >
              Localidade
            </Th>
            <Th
              sorted={sortBy === 'nome'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('nome')}
            >
              Placa
            </Th>
            <Th
              sorted={sortBy === 'dataInicialDoVideo'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('dataInicialDoVideo')}
            >
              Data
            </Th>
            <Th
              sorted={sortBy === 'percentualEmNumero'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('percentualEmNumero')}
            >
              % Filmado
            </Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {sortedData.length ? (
            sortedData.map((vehicle) => (
              <VehiclesTableRows
                key={`${vehicle.veiculoId}-${vehicle.localidade}`}
                onClick={() => { onSelectVehicle(vehicle) }}
                vehicle={vehicle}
                bg={selectedVehicle ? 'gray.1' : ''}
              />
            ))
          ) : (
            <Table.Tr>
              <Table.Td colSpan={4}>
                <Text c="darkBlue.9" ta="center">
                  Nenhum veículo foi encontrado
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}
