import { Flex, Input, Text } from '@mantine/core'
import { FiSearch } from 'react-icons/fi'

type TVehiclesFilterSearch = {
  onFilter: (plate: string) => void
}

export function VehiclesFilterSearch({ onFilter }: TVehiclesFilterSearch) {
  return (
    <Flex align="center" gap="xs" justify="flex-end" mx="lg" mt="sm">
      <Text component="span">Placa:</Text>
      <Input
        color="darkBlue.9"
        c="darkBlue.9"
        maw="15rem"
        leftSection={<FiSearch />}
        placeholder="Digite a placa"
        onChange={e => onFilter(e.currentTarget.value)}
      />
    </Flex>
  )
}
