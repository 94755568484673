import {
  Table,
  UnstyledButton,
  Group,
  Text,
  Center,
  rem,
  keys,
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import classes from "./table-sort.module.css";



interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

export function Th({ children, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function filterData(data: any, search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item:any) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
}


export function sortData(
  data: any,
  payload: { sortBy: any; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}







// import { useState } from 'react';
// import {
//   Table,
//   ScrollArea,
//   UnstyledButton,
//   Group,
//   Text,
//   Center,
//   TextInput,
//   rem,
//   keys,
// } from '@mantine/core';
// import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
// import classes from './styles.module.css';
// import {TFilterVehicle} from '@services/hooks/use-filter-vehicles';
// import {TableScrollContainerProps} from '@mantine/core/lib/components/Table/TableScrollContainer';

// // interface RowData {
// //   Placa: string;
// //   Data: string;
// //   '%Filmado': string;
// // }

// interface ThProps {
//   children: React.ReactNode;
//   reversed: boolean;
//   sorted: boolean;
//   onSort(): void;
  
// }

// function Th({ children, reversed, sorted, onSort }: ThProps) {
//   const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
//   return (
//     <Table.Th className={classes.th}>
//       <UnstyledButton onClick={onSort} className={classes.control}>
//         <Group justify="space-between">
//           <Text fw={500} fz="sm">
//             {children}
//           </Text>
//           <Center className={classes.icon}>
//             <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
//           </Center>
//         </Group>
//       </UnstyledButton>
//     </Table.Th>
//   );
// }

// type TVehiclesTable = {
//     vehicles: TFilterVehicle[]
//     onSelectVehicle: (vehicle: TFilterVehicle) => void
//     selectedVehicle?: boolean
//     search: string
//   } & Omit<TableScrollContainerProps, 'minWidth'>

// function filterData({vehicles:data,search}:TVehiclesTable) {
//   const query = search.toLowerCase().trim();
//   return data.filter((item) =>
//     keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
//   );
// }

// function sortData(
//   data: TFilterVehicle[],
//   payload: { sortBy: keyof TFilterVehicle | null; reversed: boolean; search: string }
// ) {
//   const { sortBy } = payload;

//   if (!sortBy) {
//     return filterData(data);
//   }

//   return filterData(
//     [...data].sort((a, b) => {
//       if (payload.reversed) {
//         return b[sortBy].localeCompare(a[sortBy]);
//       }

//       return a[sortBy].localeCompare(b[sortBy]);
//     }),
//     payload.search
//   );
// }

// export function TableSort() {
//   const [search, setSearch] = useState('');
//   const [sortedData, setSortedData] = useState();
//   const [sortBy, setSortBy] = useState<keyof TFilterVehicle | null>(null);
//   const [reverseSortDirection, setReverseSortDirection] = useState(false);

//   const setSorting = (field: keyof TFilterVehicle) => {
//     const reversed = field === sortBy ? !reverseSortDirection : false;
//     setReverseSortDirection(reversed);
//     setSortBy(field);
//     setSortedData(sortData(data, { sortBy: field, reversed, search }));
//   };

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const { value } = event.currentTarget;
//     setSearch(value);
//     setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
//   };

//   const rows = sortedData.map((row:TFilterVehicle) => (
//     <Table.Tr key={row.nome}>
//       <Table.Td>{row.nome}</Table.Td>
//       <Table.Td>{row.dataInicialDoVideo}</Table.Td>
//       <Table.Td>{row.percentualTotal}</Table.Td>
//     </Table.Tr>
//   ));

//   return (
//     <ScrollArea>
//       <TextInput
//         placeholder="Search by any field"
//         mb="md"
//         leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
//         value={search}
//         onChange={handleSearchChange}
//       />
//       <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
//         <Table.Tbody>
//           <Table.Tr>
//             <Th
//               sorted={sortBy === 'nome'}
//               reversed={reverseSortDirection}
//               onSort={() => setSorting('nome')}
//             >
//               Placa
//             </Th>
//             <Th
//               sorted={sortBy === 'dataInicialDoVideo'}
//               reversed={reverseSortDirection}
//               onSort={() => setSorting('dataInicialDoVideo')}
//             >
//               Data
//             </Th>
//             <Th
//               sorted={sortBy === 'percentualTotal'}
//               reversed={reverseSortDirection}
//               onSort={() => setSorting('percentualTotal')}
//             >
//               % Filmado
//             </Th>
//           </Table.Tr>
//         </Table.Tbody>
//         <Table.Tbody>
//           {rows.length > 0 ? (
//             rows
//           ) : (
//             <Table.Tr>
//               <Table.Td colSpan={Object.keys(data[0]).length}>
//                 <Text fw={500} ta="center">
//                   Nothing found
//                 </Text>
//               </Table.Td>
//             </Table.Tr>
//           )}
//         </Table.Tbody>
//       </Table>
//     </ScrollArea>
//   );
// }

