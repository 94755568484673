import { http } from '@services/axios'
import { useQuery } from 'react-query'
import { TFilterVehicle } from './use-filter-vehicles'

export async function getByToday(filter:string): Promise<TFilterVehicle[]> {

  try {
    const { data: vehiclesByToday } = await http.get<TFilterVehicle[]>(
      `/Videos/ListarVeiculosHoje?filter=${filter}`
    )

    return vehiclesByToday
  } catch (error) {
    console.log(error)
    return []
  }
}

export function useFilterVehiclesByToday(filter?:string) {
  const ft= filter || 'all'
  return useQuery({
    queryKey: [`vehicles-by-last-today-${ft}`],
    queryFn: () => getByToday(ft),
  })
  // return useQuery([`vehicles-by-last-today-${ft}`], () => getByToday(ft), {
  //   staleTime: 1000 * 60 * 60,
  //   enabled: true
  // }) //60 min
}
