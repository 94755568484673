import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { VehiclesFilter } from './vehicles-filter'
import { VehiclesTable } from './vehicles-table'
import { DatesRangeValue } from '@mantine/dates'
import { VideosList } from '@pages/videos/videos-list'
import { Transition } from '@mantine/core';
import {
  TFilterVehicle
} from '@services/hooks'
import { getByRange } from '@services/hooks/use-filter-vehicles-by-range';
import { useLoader } from '../../context/loader-context';
import { getByLastMonth } from '@services/hooks/use-filter-vehicles-by-last-month'
import { getByLastWeek } from '@services/hooks/use-filter-vehicles-by-last-week'
import { getByLastYesterday } from '@services/hooks/use-filter-vehicles-by-yesterday'
import { getByToday } from '@services/hooks/use-filter-vehicles-by-today'

export function Vehicles() {
  const { filter } = useParams();

  const { changeSpinnerShowingState } = useLoader()

  const [vehiclesByMonth, setVehiclesByMonth] = useState<TFilterVehicle[]>([])
  const [vehiclesByWeek, setVehiclesByWeek] = useState<TFilterVehicle[]>([])
  const [vehiclesByYesterday, setVehiclesByYesterday] = useState<TFilterVehicle[]>([])
  const [vehiclesByToday, setVehiclesByToday] = useState<TFilterVehicle[]>([])
  const [date, setDate] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date()
  ])
  const [selectedVehicle, setSelectedVehicle] = useState<TFilterVehicle | null>(
    null
  )
  const [filteredVehicles, setFilteredVehicles] = useState<TFilterVehicle[]>([])


  useEffect(() => {
    changeSpinnerShowingState(true);

    getByLastWeek(filter || 'all')
      .then((vehicles) => { vehicles && setVehiclesByWeek(vehicles); })
      .finally(() => { changeSpinnerShowingState(false); });

    getByLastYesterday(filter || 'all')
      .then((vehicles) => { vehicles && setVehiclesByYesterday(vehicles); })
      .finally(() => { changeSpinnerShowingState(false); });

    getByToday(filter || 'all')
      .then((vehicles) => { vehicles && setVehiclesByToday(vehicles) })
      .finally(() => { changeSpinnerShowingState(false); });

    getByLastMonth(filter || 'all')
      .then((vehicles) => {
        if (vehicles) {
          setVehiclesByMonth(vehicles)
          setFilteredVehicles(vehicles)
        }
      })
      .finally(() => { changeSpinnerShowingState(false); });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const fastFilterData = {
    month: vehiclesByMonth,
    week: vehiclesByWeek,
    today: vehiclesByToday,
    yesterday: vehiclesByYesterday
  }

  const handleChangeDate = (
    value: DatesRangeValue,
    fastFilter?: keyof typeof fastFilterData
  ) => {

    setSelectedVehicle(null)
    setDate(value)
    if (fastFilter) {
      setFilteredVehicles(fastFilterData[fastFilter] || [])
      changeSpinnerShowingState(false)
      return
    }
    getByRange(value, filter || 'all')
      .then((vehicles) => { setFilteredVehicles(vehicles); })
      .finally(() => { changeSpinnerShowingState(false); })
  }

  const handleSelectVehicle = (vehicle: TFilterVehicle) => {
    changeSpinnerShowingState(true)
    if (selectedVehicle) {
      changeSpinnerShowingState(false)
      setSelectedVehicle(null)
    } else {
      setSelectedVehicle(vehicle)
    }
  }

  const handleFilter = (vehicles: TFilterVehicle[] | null) => {
    if (vehicles) {
      setFilteredVehicles(vehicles)
    } else {
      setFilteredVehicles(vehiclesByWeek || [])
    }
  }
  return (
    <div className="m-6">
      <h1 className="mb-3 text-3xl font-medium">Veículos</h1>
      <div className="rounded-md shadow-md shadow-gray-400">
        <h2 className="p-6 pb-0 text-xl">Lista de veículos</h2>
        <VehiclesFilter
          date={date}
          filter={filter || 'all'}
          onChangeDate={handleChangeDate}
          onFilter={handleFilter}
          vehicles={filteredVehicles}
        />
        <VehiclesTable
          vehicles={selectedVehicle ? [selectedVehicle] : filteredVehicles}
          onSelectVehicle={handleSelectVehicle}
          selectedVehicle={!!selectedVehicle}
        />
      </div>
      <Transition
        mounted={!!selectedVehicle}
        transition="slide-up"
        duration={400}
        timingFunction="ease"
      >
        {transitionStyle => (
          <div
            className="my-6 rounded-md shadow-md shadow-gray-400 "
            style={{ ...transitionStyle }}
          >
            <VideosList vehicle={selectedVehicle} />
          </div>
        )}
      </Transition>
    </div>
  )
}

