/* eslint-disable react-hooks/exhaustive-deps */
import { FormEvent, useEffect, useRef, useState } from 'react'
import { ActionIcon, Modal } from '@mantine/core'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'
import ReactPlayer from 'react-player'
import { CorteDoVideo } from '../../models/corte-do-video'
import { CameraInputOutputDTO } from '../../models/camera-input-output-dto'
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull'
import { Duration, formatNumberToTime } from '../duration';
import { gerarCorteAsync, gerarDownloadAsync } from '../../services/hooks/useVideos';
import { FaPlay, FaPause, FaForward, FaBackward, FaClapperboard, FaExpand, FaDownload } from "react-icons/fa6";
import { useLoader } from '../../context/loader-context';
import { VideoInputOutputDTO } from '../../models/video-input-output-dto';
import { VideoMiniatureDTO } from '../../models/video-miniature-dto'
import { VideoAnaliseLogDto } from '../../models/video-analise-log-dto'
import { VideoMiniature } from '@components/video-miniature'
import { WarningIcon } from '@components/ui/icons/warning-icon';
import { ServiceIcon } from '@components/ui/icons/service-icon/service-icon';

type TVideosModal = {
  isOpened: boolean
  onClose: () => void
  miniatureClickHandler: (videoMiniature:VideoMiniatureDTO) => void
  videoLinks: VideoInputOutputDTO | null,
  videosMiniature: VideoMiniatureDTO[] | null,
  capacetes: VideoAnaliseLogDto[] | null,
  celular: VideoAnaliseLogDto[] | null,
  cigarro: VideoAnaliseLogDto[] | null,
  cinto: VideoAnaliseLogDto[] | null,
  videoStart: number,
}

interface videoSourceProps {
  urlStream: string,
  bucketIdDoVideoOriginal: string
}


export function VideosModal({
  isOpened,
  onClose,
  miniatureClickHandler,
  videoLinks,
  videosMiniature,
  capacetes,
  celular,
  cigarro,
  cinto,
  videoStart,

}: TVideosModal) {
  const [isMaximized, setIsMaximized] = useState(false)

  const { changeSpinnerShowingState } = useLoader()
  const [played, setPlayed] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [playing, setPlaying] = useState<boolean>(false)
  const [playbackRate, setPlaybackRate] = useState<number>(1)
  const [cortesDosVideos, setCortesDosVideos] = useState<CorteDoVideo[]>()
  const [videosSources, setVideosSources] = useState<videoSourceProps[]>()
  const videoPlayers = [
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null)
  ]
  const [localOpenModal, setLocalOpenModal] = useState(false)
  const [videoGuideKey, setVideoGuideKey] = useState<number>(0);

  useEffect(() => {
    setPlaying(false);
    setPlayed(0);
    setDuration(0);
    setPlaybackRate(1);
    feedVideoSources();
    setTimeout(() => {
      setLocalOpenModal(isOpened);
    }, 3000);
  }, [videoLinks]);


  function feedReference(player: ReactPlayer, index: number) {
    videoPlayers[index].current = player
  }
  function feedCorteCamera(videoLinks: VideoInputOutputDTO, camera: CameraInputOutputDTO, cameraNumero: number): CorteDoVideo {
    return {
      camera: camera,
      inicioDoVideo: "00:00:00",
      fimDoVideo: "00:00:00",
      nomeDoVideo: `camera${cameraNumero}_${videoLinks.veiculoNome}_${videoLinks.dataDoVideo}_${videoLinks.horaDoVideo}`,
      acoes: "gravar"
    }

  }

  function feedVideoSources() {
    changeSpinnerShowingState(true);
    setVideosSources([]);

    if (!videoLinks) {
      return;
    }

    const tempSource: videoSourceProps[] = [];
    const tempCorte: CorteDoVideo[] = [];

    if (videoLinks?.camera1?.videoLink) {
      const source1 = {
        urlStream: videoLinks.camera1.videoLink,
        bucketIdDoVideoOriginal: videoLinks.camera1.bucketIdDoVideoOriginal,
      } as videoSourceProps;

   
      tempSource.push(source1);
      tempCorte.push(feedCorteCamera(videoLinks, videoLinks.camera1, 1));
    }

    if (videoLinks?.camera2?.videoLink) {
      const source2 = {
        urlStream: videoLinks.camera2.videoLink,
        bucketIdDoVideoOriginal: videoLinks.camera2.bucketIdDoVideoOriginal,
      } as videoSourceProps;


      tempSource.push(source2);
      tempCorte.push(feedCorteCamera(videoLinks, videoLinks.camera2, 2));
    }

    if (videoLinks?.camera3?.videoLink) {
      const source3 = {
        urlStream: videoLinks.camera3.videoLink,
        bucketIdDoVideoOriginal: videoLinks.camera3.bucketIdDoVideoOriginal,
      } as videoSourceProps;

      tempSource.push(source3);
      tempCorte.push(feedCorteCamera(videoLinks, videoLinks.camera3, 3));
    }

    if (videoLinks?.camera4?.videoLink) {
      const source4 = {
        urlStream: videoLinks.camera4.videoLink,
        bucketIdDoVideoOriginal: videoLinks.camera4.bucketIdDoVideoOriginal,
      } as videoSourceProps;

      tempSource.push(source4);
      tempCorte.push(feedCorteCamera(videoLinks, videoLinks.camera4, 1));
    }

    setVideosSources(tempSource);
    setCortesDosVideos(tempCorte);
  };

  function handleSeek(seek: number) {
    setPlaying(false);
    changeSpinnerShowingState(true);

    if (!videoPlayers[videoGuideKey])
      return;

    const currentTime = videoPlayers[videoGuideKey].current!.getCurrentTime();
    videoPlayers[videoGuideKey]!.current!.seekTo(currentTime + (seek * playbackRate), "seconds");
    syncVideos(true);
  }

  function handleSliderSeek(seek: number) {
    setPlaying(false);
    changeSpinnerShowingState(true);

    if (!videoPlayers[videoGuideKey])
      return;


    videoPlayers[videoGuideKey]!.current!.seekTo(seek, "fraction");

    syncVideos(true);
    setPlaying(false);
    // syncVideos(false);
  }
  function handleDownload(event: FormEvent,source: string,) {
    event.preventDefault();
    handlePause()
    changeSpinnerShowingState(true)

    gerarDownloadAsync(source)
    .then(res => {
      res &&
      window.open(res, '_blank', 'noopener,noreferrer');
    })
    .catch(err => {
      console.log(err);
      changeSpinnerShowingState(false)
    })
    setTimeout(() => {
        changeSpinnerShowingState(false)
      }, 5000);
  }
  function handlePlay() {
    setPlaying(false);
    changeSpinnerShowingState(true);
    syncVideos(true);
  }
  function handlePause() {
    setPlaying(false);
    syncVideos(false);
  }
  function handleOnReady() {
    if (!videoPlayers[0]) return;
    changeSpinnerShowingState(true);
    videoLinks &&
      handleSeek(0)
    syncVideos(false)
    setTimeout(() => {
      changeSpinnerShowingState(false);
    }, 5000);
  }

  function beginnerAction() {
    setPlaying(false);
  }
  function handleOnPlaybackRateChange(speed: number) {
    beginnerAction();

    if (!videoPlayers[0]) {
      syncVideos(false);
      return;
    }

    const currentTime = videoPlayers[0].current!.getCurrentTime()

    videoPlayers[0].current!.seekTo(speed, "seconds");

    videoPlayers.forEach(player => {
      player.current?.seekTo(currentTime, "seconds");
    })

    syncVideos(true);
  }
  function handleChangeSlider(event: React.FormEvent<HTMLInputElement>) {
    event.preventDefault();
    const newValue = parseFloat(event.currentTarget.value);
    setPlayed(newValue)
    handleSliderSeek(newValue)
    setPlaying(false);
    handlePause()
  }

  function handleSetVideoTime(value: number) {
    if (value < 0) {
      value = 0;
    }

    if (value > 0.999999) {
      value = 0.999999;
    }

    setPlayed(value);
    handleSliderSeek(value);
    setPlaying(false);
    handlePause();
  };

  function syncVideos(shouldPlay: boolean) {

    beginnerAction();

    if (!shouldPlay) {
      setPlaying(shouldPlay);

      return;
    }

    if (!videoPlayers[videoGuideKey]) {
      beginnerAction();
      changeSpinnerShowingState(false);

      return
    }

    const currentTime = videoPlayers[videoGuideKey].current!.getCurrentTime()

    videoPlayers.forEach((player, index) => {
      player.current?.seekTo(currentTime, "seconds");

      if (index >= videoPlayers?.length - 1) {
        setTimeout(function () {
          setPlaying(shouldPlay);
          changeSpinnerShowingState(false);
        }, 500);
      }
    })
  }
  function handleClickFullscreen(source: string) {
    const videoPlayer = videoPlayers.filter(player => player.current?.props.url === source)[0];
    const divElement = videoPlayer && videoPlayer.current ? findDOMNode(videoPlayer.current) : undefined;
    divElement && screenfull.request(divElement as Element)
  }
  function findCorteDoVideo(urlVideo: string): CorteDoVideo | null | undefined {
    return cortesDosVideos?.filter(c => c.camera.videoLink === urlVideo)[0]
  }
  function findVideoPlayer(urlVideo: string): React.MutableRefObject<ReactPlayer | null> {
    return videoPlayers.filter(c => c.current?.props.url === urlVideo)[0]
  }
  function handleClickRecord(urlVideo: string) {
    const corteDoVideo = findCorteDoVideo(urlVideo)
    if (!corteDoVideo) return

    const videoPlayer = findVideoPlayer(urlVideo)
    if (!videoPlayer || !videoPlayer.current) return

    const currentTime = formatNumberToTime(videoPlayer.current.getCurrentTime())
    console.log("currentTime", currentTime)

    const tempCortesDosVideos = cortesDosVideos?.filter((c) => c.camera.videoLink !== urlVideo)
    console.log("tempCortesDosVideos", tempCortesDosVideos)

    if (!tempCortesDosVideos) return

    switch (corteDoVideo.acoes) {
      case "gravar": {
        tempCortesDosVideos.push(
          {
            ...corteDoVideo,
            inicioDoVideo: currentTime,
            acoes: 'gravando'
          }
        )
        break;
      }
      case "gravando": {
        handlePause()
        tempCortesDosVideos.push(
          {
            ...corteDoVideo,
            fimDoVideo: currentTime,
            nomeDoVideo: `${corteDoVideo.nomeDoVideo}_de_${corteDoVideo.inicioDoVideo}_ate_${currentTime}`,
            acoes: 'exportar'
          }
        )

        break;
      }
      case "exportar": {
        handlePause()
        changeSpinnerShowingState(true)

        gerarCorteAsync(corteDoVideo)
          .then(res => {

            var blob = new Blob([res], {
              type: 'Content-Disposition',
            });

            const element = document.createElement('a');
            element.href = URL.createObjectURL(blob);
            element.setAttribute('download', `${corteDoVideo.nomeDoVideo}.mp4`);
            document.body.appendChild(element);
            element.click();
            changeSpinnerShowingState(false)
          })
          .catch(err => {
            console.log(err);
            changeSpinnerShowingState(false)
          })

        tempCortesDosVideos.push(
          {
            ...corteDoVideo,
            inicioDoVideo: "00:00:00",
            fimDoVideo: "00:00:00",
            acoes: 'gravar'
          }
        )

        break;
      }
    }

    setCortesDosVideos(tempCortesDosVideos)

  }
  function handleCorteStatus(urlVideo: string) {
    const corteDoVideo = findCorteDoVideo(urlVideo)
    if (!corteDoVideo) return

    switch (corteDoVideo.acoes) {
      case "gravar": {
        return (
          <>
             <span className='sm:text-[10px] xl:text-xs'>Gravar</span>
          </>
        )
      }
      case "gravando": {
        return (
          <>
            <span className='sm:text-[10px] xl:text-xs font-extrabold text-[#e70808] animate-pulse'>Gravando...</span>
          </>
        )
      }
      case "exportar": {
        return (
          <>
            <span className='sm:text-[10px] xl:text-xs animate-pulse'>Exportar</span>
          </>
        )
      }
    }
  }
  const localOnClose = () => {
    if (isMaximized) {
      document.exitFullscreen()
    }
    setIsMaximized(false)
    setLocalOpenModal(false)
    onClose()
  }

  
  return (
    <Modal
      opened={localOpenModal}
      size={'90rem'}
      centered
      fullScreen={isMaximized}
      transitionProps={{ transition: 'fade', duration: 400 }}
      closeButtonProps={{ size: "20" }}
      onClose={localOnClose}
      classNames={{
        header: 'display-none h-4',
        title: 'flex flex-1 justify-end h-4 ',
        content: "bg-[#121522] shadow-lg",
        overlay: "bg-gray-900 bg-opacity-50",
      }}
      title={
        <ActionIcon
          variant="filled"
          aria-label="maximizar"
          size={20}
          onClick={() => {
            isMaximized
              ? document.exitFullscreen()
              : Element.prototype.requestFullscreen?.call(document.documentElement)
            setIsMaximized(prev => !prev)
          }}
        >
          {isMaximized ? (
            <FiMinimize2 color="black" size={18} />
          ) : (
            <FiMaximize2 color="black" size={14} />
          )}
        </ActionIcon>
      }
    >
        <>
          <div className='overflow-hidden text-white text-xs'>
            <div className={isMaximized
              ? 'flex  '
              : 'flex  '} >
              <div className='w-[80vw]'>
                <div className={isMaximized
                  ? "flex flex-wrap items-center justify-start "
                  : "flex flex-wrap items-center justify-start "}>
                  {
                    videosSources &&
                    videosSources.map((source, key) => (
                      <div key={key}
                        className={isMaximized
                          ? "w-[48%] rounded-md border-2 border-gray-300 shadow-lg mt-2 ml-4"
                          : "w-[40%] rounded-md border-2 border-gray-300 shadow-lg mt-2 ml-4"}
                      >
                        <div className="w-[100%] h-[100%]">
                          <div className="relative">
                            <ReactPlayer
                              playsInline
                              key={key}
                              style={{}}
                              height='88%'
                              width='100%'
                              url={source.urlStream}
                              playing={playing}
                              playbackRate={playbackRate}
                              muted={true}
                              onPlay={() => handlePlay}
                              onPause={() => handlePause}
                              onPlaybackRateChange={handleOnPlaybackRateChange}
                              onDuration={(d) => {
                                if (d > duration) {
                                  setDuration(d);
                                  setVideoGuideKey(key);
  
                                    if (!!videoStart) {
                                      handleSetVideoTime(videoStart / (d || 1) || 0);
                                    }
                                  }
                              }}
                              onProgress={(p) => {
                                if (key === 0) {
                                  setPlayed(p.played)
                                }
                              }}
                              onReady={() => handleOnReady()}
                              ref={(player) => feedReference(player!, key)}
                              config={{
                                file: {
                                  forceHLS: true,
                                }
                              }}
                            />
                          </div>                          

                          <div className="flex h-12 text-xs justify-end uppercase bg-[#0000000f] border-white rounded-b-lg border-spacing-8 relative z-10">
                            <div
                              className="p-2 m-2"
                              onClick={() => handleClickRecord(source.urlStream)}
                            >
                              <div className="cursor-pointer font-bold hover:border-b-2">
                                <div className="flex items-center justify-center">
                                  <FaClapperboard className="mx-2 sm:size-2 xl:size-3" />
                                  {handleCorteStatus(source.urlStream)}
                                </div>
                              </div>
                            </div>
                            <div
                              className="p-2 m-2"
                              onClick={(event) => handleDownload(event, source.bucketIdDoVideoOriginal)}
                            >
                              <div className="cursor-pointer font-bold hover:border-b-2">
                                <div className="flex items-center justify-center">
                                  <FaDownload className="mx-2 sm:size-2 xl:size-3" />
                                  <span className="sm:text-[10px] xl:text-xs">Download</span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="p-2 m-2"
                              onClick={() => handleClickFullscreen(source.urlStream)}
                            >
                              <div className="cursor-pointer font-bold hover:border-b-2">
                                <div className="flex items-center justify-center">
                                  <FaExpand className="mx-2 sm:size-2 xl:size-3" />
                                  <span className="sm:text-[10px] xl:text-xs">FullScreen</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className={isMaximized
                  ? 'w-[110%]'
                  : 'w-[90%]'}>
                  <div className={
                    isMaximized
                      ? 'p-4 px-1 flex items-center justify-center  bg-[darkBlue.9] rounded-lg shadow-sm'
                      : 'p-4 flex items-center justify-center  bg-[darkBlue.9] rounded-lg shadow-sm'
                  }>
                    <input
                      className='w-[90%] mt-2'
                      style={
                        {

                          WebkitAppearance: 'none',
                          background: 'white',
                          borderRadius: '25px',
                          height: '5px',
                          WebkitTextStrokeColor: 'white',
                          caretColor: 'white',
                          color: 'white',
                          caretShape: 'bar',
                          animation: 'linear',
                          animationDuration: '1s',
                        }
                      }
                      type='range' min={0} max={0.999999} step='any'
                      value={played}
                      onChange={(e) => handleChangeSlider(e)}
                    />
                    <div className='w-[18%] ml-2'>
                      <Duration seconds={duration * played} /> / <Duration seconds={duration} /></div>
                  </div>
                  <div className={isMaximized
                    ? 'flex w-[90%]  content-around justify-around uppercase'
                    : 'flex w-[90%] content-around justify-around uppercase'}>
                    <div className='flex'>
                      <div
                        onClick={() => syncVideos(!playing)}>
                        {playing
                          ? (
                            <div className='px-2'>
                              <div className='flex cursor-pointer hover:border-b-2'>
                                <FaPause className='mx-2' />
                                <span className=''>Pausar</span>
                              </div>
                            </div>
                          )
                          : (
                            <div className='px-2 '>
                              <div className='flex cursor-pointer hover:border-b-2'>
                                <FaPlay className='mx-2' />
                                <span className=''>Iniciar</span>
                              </div>
                            </div>
                          )
                        }
                      </div>
                      <div
                        onClick={() => handleSeek(-10)}
                      >
                        <div className='px-2 '>
                          <div className='flex cursor-pointer hover:border-b-2'>
                            <FaBackward className='mx-2' />
                            <span className=''>Recuar</span>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSeek(10)}
                      >
                        <div className='px-2 '>
                          <div className='flex cursor-pointer hover:border-b-2'>
                            <FaForward className='mx-2' />
                            <span className=''>Avançar</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='flex'>
                      <div className='px-2' onClick={() => setPlaybackRate(1)}>
                        <div className="cursor-pointer hover:border-b-2">
                          <span className={playbackRate === 1 ? 'bg-white p-1 rounded-sm text-[#121522]' : ''}>1</span>
                        </div>
                      </div>
                      <div className='px-2' onClick={() => setPlaybackRate(2)}>
                        <div className="cursor-pointer hover:border-b-2">
                          <span className={playbackRate === 2 ? 'bg-white p-1 rounded-sm text-[#121522]' : ''}>2</span>
                        </div>
                      </div>
                      <div className='px-2' onClick={() => setPlaybackRate(5)}>
                        <div className="cursor-pointer hover:border-b-2">
                          <span className={playbackRate === 5 ? 'bg-white p-1 rounded-sm text-[#121522]' : ''}>5</span>
                        </div>
                      </div>
                      <div className='px-2' onClick={() => setPlaybackRate(10)}>
                        <div className="cursor-pointer hover:border-b-2">
                          <span className={playbackRate === 10 ? 'bg-white p-1 rounded-sm text-[#121522]' : ''}>10</span>
                        </div>
                      </div>
                      <div className='px-2' onClick={() => setPlaybackRate(15)}>
                        <div className="cursor-pointer hover:border-b-2">
                          <span className={playbackRate === 15 ? 'bg-white p-1 rounded-sm text-[#121522]' : ''}>15</span>
                        </div>
                      </div>

                    </div>
                    {
                      videoLinks && videosSources && (
                        <div className={isMaximized
                          ? 'flex justify-between content-between mx-32'
                          : 'flex justify-between content-between mx-10'}>
                          <div className='px-2'>
                            <span className=''>Veículo: {videoLinks.veiculoNome}</span>
                          </div>

                          <div className='px-2'>
                            <span>Data: {videoLinks.dataDoVideo}</span>
                          </div>
                          <div className='px-2'>
                            <span>Hora: {videoLinks.horaDoVideo}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
             
         
              <div
                className={isMaximized
                  ? 'sm:w-[10rem] xl:w-[23rem] mt-2 p-0'
                  : 'sm:w-[10rem] xl:w-[20rem] mt-2 p-0 sm:ml-[-5rem] xl:ml-[-10rem]'
                }
              >
                <VideoMiniature
                  isMaximized={isMaximized}
                  videosMiniature={videosMiniature}
                  miniatureClickHandler={miniatureClickHandler}
                />
                <div className="flex sm:h-[10%] xl:h-[25%] w-[100%] mt-4 sm:overflow-x-scroll xl:overflow-hidden">
                  <div className="flex w-[100%] justify-start mt-1 gap-3">
                    {videoLinks?.videoDeServico && !!videoLinks?.inicioDoServico && (
                      <button
                        className="w-fit h-fit p-1 bg-transparent rounded-sm hover:bg-blue-500"
                        type="button"
                        title="Serviço identificado"
                        onClick={() => handleSetVideoTime(videoLinks.inicioDoServico / duration) }
                      >
                        <ServiceIcon className="fill-white w-7 h-7" />
                      </button>
                    )}
                    {videoLinks?.videoDeAlerta && !!videoLinks?.inicioDoAlerta && (
                      <button
                        className="w-fit h-fit p-1 bg-transparent rounded-sm hover:bg-blue-500"
                        type="button"
                        title="Alerta identificado"
                        onClick={() => handleSetVideoTime(videoLinks?.inicioDoAlerta / duration)}
                      >
                        <WarningIcon className="fill-white w-7 h-7" />
                      </button>
                    )}
                    {/* <DetectionAlert isMaximized={isMaximized} Icon={FaHelmetSafety} title="Identificação de capacete" videosAnaliseLogs={capacetes} handleSeek={externalHandleSeek} />
                    <DetectionAlert isMaximized={isMaximized} Icon={FaMobileRetro} title='Utilização de celular' videosAnaliseLogs={celular} handleSeek={externalHandleSeek} />
                    <DetectionAlert isMaximized={isMaximized} Icon={GiCigarette} title='Utilização de cigarro' videosAnaliseLogs={cigarro} handleSeek={externalHandleSeek} />
                    <DetectionAlert isMaximized={isMaximized} Icon={GiBelt} title='Não utilização de cinto' videosAnaliseLogs={cinto} handleSeek={externalHandleSeek} /> */}
                  </div>
                </div>
              </div>        
            </div>
          </div>
        </>
    </Modal>
  )
}


