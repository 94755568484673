import { Button, Flex } from '@mantine/core'
import { DatePickerInput, DatesRangeValue } from '@mantine/dates'
import { useEffect, useState } from 'react'

export type TFastFilter = 'month' | 'week' | 'today' | 'yesterday' 
type TVehiclesFilterDate = {
  date: [Date | null, Date | null]
  filter:string,
  onChangeDate: (
    value: DatesRangeValue,
    fastFilter?:TFastFilter
  ) => void
}

export function VehiclesFilterDate({
  date,
  filter,
  onChangeDate,
}: TVehiclesFilterDate) {

  const [currentFilter, setCurrentFilter] = useState<'month' | 'week' | 'today' | 'yesterday' | DatesRangeValue>("month")

  useEffect(() => {
    handleChangeMonth()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const handleChangeRange = (value: DatesRangeValue) => {
    setCurrentFilter(value)
    // console.log(typeof currentFilter)
    onChangeDate(value)
  }
  const handleChangeMonth = () => {
    const today = new Date()
    const monthDays = 30
    const secondDate = new Date()
    secondDate.setDate(secondDate.getDate() - monthDays)
    setCurrentFilter('month')
    onChangeDate([secondDate, today], 'month')
  }

  const handleChangeWeek = () => {
    const today = new Date()
    const weekDays = 7
    const secondDate = new Date()
    secondDate.setDate(secondDate.getDate() - weekDays)
    setCurrentFilter('week')
    onChangeDate([secondDate, today], 'week')
  }
  const handleChangeYesterday = () => {
    const yesterdayDays = 1
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - yesterdayDays)
    setCurrentFilter('yesterday')
    onChangeDate([yesterday, yesterday], 'yesterday')
  }

  const handleChangeToday = () => {
    const today = new Date()
    setCurrentFilter('today')
    onChangeDate([today, today], 'today')
  }

  return (
    <Flex
      mx="lg"
      align="center"
      justify="flex-end"
      gap="sm"
      my="md"
      wrap="wrap"
    >
      <span>Filtro:</span>
      <Button
        size="xs"
        color={currentFilter === "month" ? "white" : "darkBlue.9"}
        bg={currentFilter === "month" ? 'darkBlue.9' : 'white'}
        variant="outline"
        onClick={handleChangeMonth}
      >
        Último mês
      </Button>
      <Button
        size="xs"
        color={currentFilter === "week" ? "white" : "darkBlue.9"}
        bg={currentFilter === "week" ? 'darkBlue.9' : 'white'}
        variant="outline"
        onClick={handleChangeWeek}
      >
        Última semana
      </Button>
      <Button
        size="xs"
        color={currentFilter === "yesterday" ? "white" : "darkBlue.9"}
        bg={currentFilter === "yesterday" ? 'darkBlue.9' : 'white'}
        variant="outline"
        onClick={handleChangeYesterday}
      >
        Ontem
      </Button>
      <Button
        size="xs"
        color={currentFilter === "today" ? "white" : "darkBlue.9"}
        bg={currentFilter === "today" ? 'darkBlue.9' : 'white'}
        variant="outline"
        onClick={handleChangeToday}
      >
        Hoje
      </Button>
      <DatePickerInput
        locale="pt-br"
        className="w-52"
        valueFormat="DD/MM/YYYY"
        type="range"
        placeholder="Selecione um período"
        value={date}
        onChange={handleChangeRange}
        allowSingleDateInRange
      />
    </Flex>
  )
}