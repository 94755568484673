import React from 'react';
import { VideoMiniatureDTO } from '../../models/video-miniature-dto';

export type VideoMiniatureProps = {
    isMaximized: boolean;
    videosMiniature: VideoMiniatureDTO[]|null
    miniatureClickHandler: (videoMiniature: VideoMiniatureDTO) => void

};


export function VideoMiniature({ isMaximized, videosMiniature,miniatureClickHandler }: VideoMiniatureProps) {
    return (
        <>
            <div className={`
                ${isMaximized
                    ? 'sm:h-[35rem] xl:h-[56rem] overflow-y-scroll  mr-[-0.25rem] mb-2'
                    : 'sm:h-[35rem] xl:h-[40rem] overflow-y-scroll mr-[-0.25rem] mb-2 '}`}>
                {videosMiniature?.map((videoMiniature) => (
                    videoMiniature.videoId&&
                    <div className='flex cursor-pointer' id={`${videoMiniature.videoId}`} onClick={()=>{miniatureClickHandler(videoMiniature)}}>
                        <div className='flex flex-col items-center justify-center w-[100%] h-[100%]'>
                            {videoMiniature.thumbnailLink
                                ? (<img className='p-2 rounded-[25px] w-[100%] h-[100%]' src={videoMiniature.thumbnailLink} alt="" />)
                                :  (<div className='p-2 rounded-[25px] w-[100%] h-[100%] bg-black'></div>)}
                            <div className='mb-4'>
                                Hora: {videoMiniature.hour}
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </>);
}