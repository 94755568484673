import { DatesRangeValue } from '@mantine/dates'
import { TFastFilter, VehiclesFilterDate } from './vehicles-filter-date'
import { VehiclesFilterSearch } from './vehicles-filter-search'
import { TFilterVehicle } from '@services/hooks/use-filter-vehicles'

type TVehiclesFilter = {
  date: [Date | null, Date | null]
  filter:string,
  onChangeDate: (
    value: DatesRangeValue,
    fastFilter?:TFastFilter
  ) => void
  onFilter: (vehicles: TFilterVehicle[] | null) => void
  vehicles: TFilterVehicle[]
}

export function VehiclesFilter({
  date,
  onChangeDate,
  filter,
  onFilter,
  vehicles
}: TVehiclesFilter) {
  const handleSearchPlate = (plate: string) => {
    const filteredVehicles = !!plate
      ? vehicles.filter(vehicle =>
          vehicle.nome.toLowerCase().includes(plate.toLowerCase())
        )
      : null
    onFilter(filteredVehicles)
  }

  return (
    <>
      <VehiclesFilterSearch onFilter={handleSearchPlate} />
      <VehiclesFilterDate date={date} 
      onChangeDate={onChangeDate} 
      filter={filter}
 />
    </>
  )
}
