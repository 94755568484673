/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from '@mantine/core'
import { TFilterVehicle } from '@services/hooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import { TVehiclesCamerasByDate } from './types'
import { VideoBadge } from './video-badge'
import { VideosModal } from '@components/videos-modal/videos-modal';
import { useLoader } from '../../context/loader-context';
import { VideoInputOutputDTO } from '../../models/video-input-output-dto'
import { VideoMiniatureDTO } from '../../models/video-miniature-dto'
import { listarVideosAsync, gerarLinksAsync } from '@services/hooks/useVideos';
import {DateConverter} from '@utils/date-converter';


type TVideosList = {
  vehicle: TFilterVehicle | null
}

export function VideosList({ vehicle }: TVideosList) {
  const [openModal, setOpenModal] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<VideoInputOutputDTO | null>(null)
  const [videosMiniature, setVideosMiniature] = useState<VideoMiniatureDTO[] | null>(null)
  const fullLineRef = useRef<HTMLTableCellElement>(null)
  const [videos, setVideos] = useState<TVehiclesCamerasByDate[]>([])
  const [minHour, setMinHour] = useState<number>(8)
  const [maxHour, setMaxHour] = useState<number>(18)
  const [videoStart, setVideoStart] = useState<number>(0)
  const { changeSpinnerShowingState } = useLoader()


  const getVehicleVideosByDate = useCallback(
    async (initialDate: string, finalDate?: string) => {
      try {
        const vehicleCamera = await listarVideosAsync(vehicle?.veiculoId!, initialDate, finalDate)
        changeSpinnerShowingState(false)
        return vehicleCamera
      } catch (error) {
        changeSpinnerShowingState(false)
        console.error(`Error fetching data for ${initialDate} - ${finalDate}:`, error)
      }
    },
    [vehicle?.veiculoId]
  )

  const localGerarLinksCallBack = useCallback(
    async (selectedVideo: VideoInputOutputDTO | null): Promise<VideoInputOutputDTO | undefined> => {
      try {
        const videoLinks = await gerarLinksAsync(selectedVideo!)
        return videoLinks
      } catch (error) {
        changeSpinnerShowingState(false)
        console.error(`Error fetching data for `, error)
      }
    },
    []
  )


  useEffect(() => {
    if (openModal) changeSpinnerShowingState(true)
  }, [openModal])

  useEffect(() => {
    changeSpinnerShowingState(true)
    if (vehicle?.dataInicialDoVideo && vehicle?.dataFinalDoVideo) {
      const startDateObj = new Date(vehicle.dataInicialDoVideo)
      const endDateObj = new Date(vehicle.dataFinalDoVideo)


      const getVideos = async () => {
        const formattedStartDate = startDateObj.toISOString().split('T')[0]
        const formattedFinalDate = endDateObj.toISOString().split('T')[0]
        const vehiclesCamera = await getVehicleVideosByDate(formattedStartDate, formattedFinalDate)
        for (let date = startDateObj; date <= endDateObj; date.setDate(date.getDate() + 1)) {
          const formattedDate = date.toISOString().split('T')[0]
          const vehicleCamera = vehiclesCamera?.filter(f => f.dataDoVideo === formattedDate)
          if (!vehicleCamera || vehicleCamera.length === 0) continue
          setVideos(prevState => {
            const dateIndex = prevState.findIndex(
              element => element.date === formattedDate
            )
            if (dateIndex === -1) {
              return [
                ...prevState,
                {
                  date: formattedDate,
                  vehicles: vehicleCamera || []
                }
              ]
            } else {
              return prevState.map(element =>
                element.date === formattedDate
                  ? {
                    date: formattedDate,
                    vehicles: vehicleCamera || []
                  }
                  : element
              )
            }
          })
        }
      }
      getVideos()
    }
  }, [getVehicleVideosByDate, vehicle?.dataFinalDoVideo, vehicle?.dataInicialDoVideo])

  const localGerarLinksAsync = async (selectedVideo: VideoInputOutputDTO) => {
    return await localGerarLinksCallBack(selectedVideo)
  }

  videos?.forEach(video => {
    video.vehicles?.forEach(vehicle => {
      const hour = vehicle.horaDoVideo.split(":")[0]
      if (parseInt(hour) < minHour) {
        setMinHour(parseInt(hour))
      }
      if (parseInt(hour) > maxHour) {
        setMaxHour(parseInt(hour))
      }
    })
  })
  const videoBadgeHandler = (videoData: VideoInputOutputDTO) => {
    changeSpinnerShowingState(true);
    const defaultThumb = process.env.REACT_APP_DEFAULT_THUMB
    const tempVideosMiniature = videos.filter(v => v.date === videoData.dataDoVideo)[0]
      .vehicles
      .map(vehicle => {
        if (!vehicle || !vehicle.id) return {} as VideoMiniatureDTO
        return {
          hour: vehicle.horaDoVideo,
          dateOfVideo: vehicle.dataDoVideo, 
          thumbnailLink: vehicle.linkDaThumbnail ? vehicle.linkDaThumbnail : defaultThumb,
          videoId: vehicle.id
        } as VideoMiniatureDTO

      })
    setVideosMiniature(tempVideosMiniature)
    localGerarLinksAsync(videoData)
      .then((links) => {
        if (!links) return

        if (links?.camera2) {
          links.camera2 = {
            ...links.camera2,
          };
        };

        setSelectedVideo(links)
        setOpenModal(true)
      })
      .catch((error) => {
        console.error('Error fetching data for ', error)
      })
  }

  const modalOnClose = () => {
    setSelectedVideo(null)
    setOpenModal(false)
  }

  const miniatureClickHandler = (videoMiniature: VideoMiniatureDTO) => {
    changeSpinnerShowingState(true)
    modalOnClose()
    setTimeout(() => {
      const tempVideo = videos.filter(v => v.date===videoMiniature.dateOfVideo)[0]
      .vehicles.filter(v => v.id === videoMiniature.videoId)[0]
      videoBadgeHandler(tempVideo)

    }, 3000)
  }

  const hoursHeader = Array.from({ length: maxHour - minHour + 1 }, (_, i) => minHour + i)
  return (
    <>
      {openModal && (
        <VideosModal
          videoLinks={selectedVideo}
          isOpened={openModal}
          videosMiniature={videosMiniature}
          miniatureClickHandler={miniatureClickHandler}
          onClose={modalOnClose}
          capacetes={null}
          celular={null}
          cigarro={null}
          cinto={null}
          videoStart={videoStart || 0}
        />
      )}
      <Table.ScrollContainer minWidth="30rem" w="100%" px="5rem">
        <Table withColumnBorders w="100%" className="overflow-x-hidden">
          <Table.Thead>
            <Table.Tr>
              <Table.Th w="7rem" />
              {hoursHeader.map(hour => (
                <Table.Th className='sm:text-[10px] xl:text-sm' key={hour}>{`${hour}:00`}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {videos.map((videosData, cameraIndex) => (
              <Table.Tr key={videosData.date}>
                <Table.Td align="center" className='sm:text-[10px] xl:text-sm'>
                  {DateConverter.ConvertSystemDateFormatToBRLDate(videosData.date)}
                </Table.Td>
                {hoursHeader.map((hour) => (
                  <Table.Td
                    px="0.5rem"
                    ref={fullLineRef}
                    style={{
                      position: "relative",
                      // overflow: "hidden",
                    }}
                  >
                    <>
                      {videosData.vehicles?.filter(f => parseInt(f.horaDoVideo.split(":")[0]) === hour)?.map(videoData => (
                        videoData && videoData.id && (
                          <VideoBadge
                            key={videoData.id}
                            videoData={videoData}
                            onClickBadge={() => videoBadgeHandler(videoData)}
                            onClickBullet={(start?: number) => setVideoStart(start || 0)}
                            type={videoData.videoDeServico ? 'servico' : videoData.videoDeAlerta ? 'alerta' : 'padrao'}
                            zIndex={videoData.videoDeServico || videoData.videoDeAlerta ? 500 : 100}
                          />
                        )
                      ))}
                    </>
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  )
}
