export class DateConverter {
    public static ConvertSystemDateFormatToBRLDate(date: string) {
        const [year, month, day] = date.split("-")
        if (!year || !month || !day) return null
        return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year.substring(2, 4)}`
    }

    public static ConvertBRLDateToSystemDateFormat(date: string) {
        const [day, month, year] = date.split("/")

        if (!day || !month || !year || year.length !== 4) return null

        return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    }
    public static ConvertStringTimeSpanToHourMinuteSecond(timeSpan: string) {
        const [hour, minute, second] = timeSpan.split(":")
        if (!hour || !minute || !second) return null
        return `${hour}h ${minute}m`
    }

    public static ConvertDateToSystemDateFormat(date: Date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }
}

