import { MantineColorsTuple, MantineProvider, createTheme } from '@mantine/core'
import { BrowserRouter } from 'react-router-dom'
import 'dayjs/locale/pt-br'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { msalConfig } from '@services/auth-config'
import { Router } from '@routes/Router'
import { Navbar } from './components'


const darkBlue: MantineColorsTuple = [
  '#f2f3f8',
  '#e2e3e9',
  '#c1c3d4',
  '#9ea2bf',
  '#8086ae',
  '#6e75a4',
  '#646ca0',
  '#535b8c',
  '#49517e',
  '#2E3454'
]

const lightBlue: MantineColorsTuple = [
  '#e3f8ff',
  '#d3ecfb',
  '#abd5ee',
  '#80bee3',
  '#5caad9',
  '#449dd3',
  '#3497d1',
  '#2283ba',
  '#0f75a8',
  '#006596'
]

const theme = createTheme({
  colors: {
    darkBlue,
    lightBlue
  }
})

const msalInstance = new PublicClientApplication(msalConfig)
const queryClient = new QueryClient()

export function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        {/* {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )} */}
        <MantineProvider theme={theme}>
          <BrowserRouter>
            <div className="flex h-svh">
              <Navbar />
              <div className="w-full overflow-auto ">

                <Router />
              </div>
            </div>
          </BrowserRouter>
        </MantineProvider>
      </QueryClientProvider>
    </MsalProvider>
  )
}
