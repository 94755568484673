import { http } from '../axios'
import { useQuery } from 'react-query'
import { TFilterVehicle } from './use-filter-vehicles'
import { DatesRangeValue } from '@mantine/dates';
import { DateConverter } from '@utils/date-converter';

export async function getByRange(dateRange: DatesRangeValue, filter: string): Promise<TFilterVehicle[]> {

  try {
    const [from, to] = dateRange
    if (!from || !to) return []

    const formattedFrom = DateConverter.ConvertDateToSystemDateFormat(from)
    const formattedTo = DateConverter.ConvertDateToSystemDateFormat(to)

    const { data: vehiclesByToday } = await http.get<TFilterVehicle[]>(
      `/Videos/ListarVeiculosPorData/${formattedFrom}/${formattedTo}?filter=${filter}`
    )

    return vehiclesByToday
  } catch (error) {
    console.log(error)
    return []
  }
}

export function useFilterVehiclesByRange(dateRange: DatesRangeValue, filter?: string) {
  return useQuery(`vehicles-by-last-today-${filter}`, () => getByRange(dateRange, filter ?? 'all'), {
    staleTime: 1000 * 60 * 60,
    enabled: true
  }) //60 min
}
