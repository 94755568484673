import { Route, Routes } from 'react-router-dom'

import { PrivateRoutes } from './PrivateRoutes'
import { useAPIToken } from '@services/hooks';

export function Router() {
  useAPIToken();
  
  return (
    <Routes>
      <Route path="/vehicles/:filter" element={<PrivateRoutes />} />
    </Routes>
  );
};
