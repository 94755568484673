import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { LoaderProvider } from './context/loader-context'

import '@mantine/dates/styles.css'
import '@mantine/core/styles.css'
import './tailwind.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <>
    <LoaderProvider>
      <App />
    </LoaderProvider>
  </>
  // </React.StrictMode>
)
