import { CorteDoVideo } from "../../models/corte-do-video";
import { VideoAnaliseLogDto } from "../../models/video-analise-log-dto";
import { VideoInputOutputDTO } from "../../models/video-input-output-dto";
import { VideoMetaDadoDto } from "../../models/video-meta-dado-dto";
import { http } from "../axios";
import dayjs, { Dayjs } from "dayjs";

export async function listarVideosAsync(veiculoId: string, dataInicialDoVideo: string, dataFinalDoVideo?: string): Promise<VideoInputOutputDTO[]> {

  if (veiculoId === null) return [];
  if (dataInicialDoVideo === null) return [];

  const initialDateInDayJS = dayjs(dataInicialDoVideo)
  const finalDateInDayJS = dataFinalDoVideo ? dayjs(dataFinalDoVideo) : dayjs(dataInicialDoVideo)

  const { data } = await http.get<VideoInputOutputDTO[]>(
    `Videos/ListarVideos/${veiculoId}/${initialDateInDayJS.format('YYYY-MM-DD')}/${finalDateInDayJS.format('YYYY-MM-DD')}`);
  return data
}
export async function listarVideosDeServicoAsync(veiculoId: string, dataDoVideo: Dayjs): Promise<VideoInputOutputDTO[]> {

  if (veiculoId === null) return [];
  if (dataDoVideo === null) return [];

  const { data } = await http.get(`Videos/ListarVideosDeServico/${veiculoId}/${dataDoVideo.format('YYYY-MM-DD')}`);

  return handleVideos(data);
}
export async function listarVideosDeAnomaliasAsync(veiculoId: string, dataDoVideo: Dayjs): Promise<VideoInputOutputDTO[]> {

  if (veiculoId === null) return [];
  if (dataDoVideo === null) return [];

  const { data } = await http.get(`Videos/ListarVideosDeAnomalia/${veiculoId}/${dataDoVideo.format('YYYY-MM-DD')}`);

  return handleVideos(data);
}
export async function gerarLinksAsync(videoInputOutputDTO: VideoInputOutputDTO): Promise<VideoInputOutputDTO> {
  const { data } = await http.post('Videos/GeraLinks', videoInputOutputDTO);
  return data;
}
export async function gerarCorteAsync(corteDoVideoDTO: CorteDoVideo): Promise<any> {
  console.log("corteDoVideoDTO", corteDoVideoDTO)

  const { fimDoVideo, inicioDoVideo } = corteDoVideoDTO
  const [inicioHour, inicioMinute, inicioSecond] = inicioDoVideo.split(':')
  const [fimHour, fimMinute, fimSecond] = fimDoVideo.split(':')

  const { data } = await http.post('Videos/GeraCorte', {
    ...corteDoVideoDTO,
    inicioDoVideo: `${String(inicioHour).padStart(2, '0')}:${String(inicioMinute).padStart(2, '0')}:${String(inicioSecond).padStart(2, '0')}`,
    fimDoVideo: `${String(fimHour).padStart(2, '0')}:${String(fimMinute).padStart(2, '0')}:${String(fimSecond).padStart(2, '0')}`
  }, {
    responseType: 'blob',
  });
  return data;
}
export async function gerarDownloadAsync(bucketId: string): Promise<string> {
    const { data } = await http.get(`Videos/Download/${bucketId}`);
    return data;
}
export async function ListarVideoMetaDadosAsync(videoId: number): Promise<VideoMetaDadoDto[]> {
  const { data } = await http.get(`Videos/ListarVideoMetaDados/${videoId}`);
  return data;
}
export async function ListarVideoAnaliseLogAsync(videoIdCamera2: number | undefined, videoIdCamera3: number | undefined): Promise<VideoAnaliseLogDto[]> {
  if (!videoIdCamera2 && !videoIdCamera3) return []
  const camera2 = videoIdCamera2 === undefined || videoIdCamera2 === null ? 0 : videoIdCamera2
  const camera3 = videoIdCamera3 === undefined || videoIdCamera3 === null ? 0 : videoIdCamera3

  const { data } = await http.get(`Videos/ListarVideoAnaliseLog/${camera2}/${camera3}`);
  return data;
}

const handleVideos = (data: VideoInputOutputDTO[]): VideoInputOutputDTO[] => {
  const videos: VideoInputOutputDTO[] = data.map((video: VideoInputOutputDTO) => handleVideo(video));
  return videos;
}

const handleVideo = (video: VideoInputOutputDTO): VideoInputOutputDTO => {
  return {
    ...video,
    dataDoVideo: dayjs(video.dataDoVideo).format("DD/MM/YYYY"),
  };
}
