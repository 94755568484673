import { http } from '@services/axios';
import { useQuery } from 'react-query';

// import { http } from '@services/axios';

export type TBaseLink = {
  id: string;
  nome: string;
  chave: string;
};

export async function getBasesLinks(): Promise<TBaseLink[]> {
  try {
    const { data: bases } = await http.get<TBaseLink[]>('/Videos/ListarBases');

    return bases;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useGetBasesLinks() {
  return useQuery({
    queryKey: [`bases-links`],
    queryFn: () => getBasesLinks(),
  });
};
