import { http } from '@services/axios'
import { useQuery } from 'react-query'
import { TFilterVehicle } from './use-filter-vehicles'

export async function getByLastWeek(filter:string): Promise<TFilterVehicle[]> {
  try {
    const { data: vehiclesByWeek } = await http.get<TFilterVehicle[]>(
      `/Videos/ListarVeiculosUltimaSemana?filter=${filter}`
    )

    return vehiclesByWeek
  } catch (error) {
    console.log(error)
    return []
  }
}

export function useFilterVehiclesByLastWeek(filter?:string) {
 const  ft = filter || 'all'
 return useQuery({
    queryKey: [`vehicles-by-last-week-${ft}`],
    queryFn: () => getByLastWeek(ft),
 })
  // return useQuery([`vehicles-by-last-week-${ft}`], () => getByLastWeek(ft), {
  //   staleTime: 1000 * 60 * 60,
  //   enabled: true
  // }) //60 min
}
