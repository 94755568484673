
import React, { createContext, ReactNode, useContext, useMemo, useState } from "react"

type LoaderProps = {
    children: ReactNode
}

type LoaderContextProps = {
    changeSpinnerShowingState(value: boolean): void
    spinnerShowing: boolean
}
const LoaderContext = createContext<LoaderContextProps>({} as LoaderContextProps)
export function useLoader() {
    return useContext(LoaderContext)
}
export function LoaderProvider({ children }: LoaderProps) {

    const [spinnerShowing, setSpinnerShowing] = useState(false)

    function changeSpinnerShowingState(value: boolean) {
        !!value
            ? setSpinnerShowing(true)
            :setTimeout(() => {setSpinnerShowing(false)}, 500)

    }
    const value = useMemo(() => ({
        spinnerShowing,
        changeSpinnerShowingState
    }), [spinnerShowing])
    return (
        <LoaderContext.Provider value={value}>
            {children}
        </LoaderContext.Provider>
    )

}