import { SVGProps } from 'react';

type TWarningIcon = {} & SVGProps<SVGSVGElement>

export function ServiceIcon({ ...props }: TWarningIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={0}
      {...props}
    >
      <path d="M18.9,21l-5.48-5.48,2.1-2.1,5.48,5.48-2.1,2.1ZM5.1,21l-2.1-2.1,6.9-6.9-1.7-1.7-.7.7-1.28-1.28v2.05l-.7.7-3.03-3.03.7-.7h2.05l-1.25-1.25,3.55-3.55c.33-.33.69-.58,1.08-.73s.78-.23,1.18-.23.79.08,1.18.23.74.39,1.08.73l-2.3,2.3,1.25,1.25-.7.7,1.7,1.7,2.25-2.25c-.07-.18-.12-.38-.16-.58s-.06-.4-.06-.6c0-.98.34-1.81,1.01-2.49s1.5-1.01,2.49-1.01c.25,0,.49.03.71.08s.45.13.69.23l-2.48,2.48,1.8,1.8,2.48-2.48c.12.23.2.46.24.69s.06.46.06.71c0,.98-.34,1.81-1.01,2.49s-1.5,1.01-2.49,1.01c-.2,0-.4-.02-.6-.05s-.39-.09-.58-.18l-11.25,11.25Z" />
    </svg>
  );
};
