import { SVGProps } from 'react';

type TWarningIcon = {} & SVGProps<SVGSVGElement>

export function WarningIcon({ ...props }: TWarningIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth={0}
      {...props}
    >
      <path d="M1,21L12,2l11,19H1ZM4.45,19h15.1l-7.55-13-7.55,13ZM12,18c.28,0,.52-.1.71-.29.19-.19.29-.43.29-.71s-.1-.52-.29-.71c-.19-.19-.43-.29-.71-.29s-.52.1-.71.29-.29.43-.29.71.1.52.29.71c.19.19.43.29.71.29ZM11,15h2v-5h-2v5Z" />
    </svg>
  );
};
