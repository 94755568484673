import { useEffect } from 'react'
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate
} from '@azure/msal-react'
import { Vehicles } from '@pages/vehicles'

export function PrivateRoutes() {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    console.log(inProgress, isAuthenticated)
    if (inProgress === 'none' && !isAuthenticated) {
      instance.loginRedirect()
    }
  }, [instance, isAuthenticated, inProgress])

  return (
    <AuthenticatedTemplate>
      <Vehicles />
    </AuthenticatedTemplate>
  )
}

export default PrivateRoutes
