import { Group, Progress, Table, TableTrProps, Text } from '@mantine/core'
import { TFilterVehicle } from '@services/hooks/use-filter-vehicles'

type TVehiclesTableRows = {
  onClick: () => void
  vehicle: TFilterVehicle
} & TableTrProps

export function VehiclesTableRows({
  onClick,
  vehicle,
  ...props
}: TVehiclesTableRows) {
  const percentParsed = parseFloat(vehicle.percentualTotal)
  const positiveReviews = percentParsed
  const negativeReviews = 100 - percentParsed

  return (
    <>
    <Table.Tr className="cursor-pointer" onClick={onClick} {...props} >
      <Table.Td>{vehicle.localidade}</Table.Td>
      <Table.Td>{vehicle.nome}</Table.Td>
      <Table.Td>
        {vehicle.dataInicialDoVideo} - {vehicle.dataFinalDoVideo}
      </Table.Td>
      {/* <Table.Td>{alerts}</Table.Td> */}
      <Table.Td w="40%">
        <Group justify="space-between">
          <Text fz="xs" c="darkBlue.9" fw={700}>
            {vehicle.percentualTotal}
          </Text>
        </Group>
        <Progress.Root>
          <Progress.Section value={positiveReviews} color="darkBlue.9" />

          <Progress.Section value={negativeReviews} color="gray.4" />
        </Progress.Root>
      </Table.Td>
    </Table.Tr>
    </>
  )
}
