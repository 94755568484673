import {
  FiChevronRight,
  FiChevronLeft
} from 'react-icons/fi'
import { FaBuildingUser } from 'react-icons/fa6'
import { ActionIcon, Center, Loader, Stack, Text } from '@mantine/core'
import { Logo } from '@ui/Logo'
import { NavbarLink } from './navbar-link'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useLoader } from '../../context/loader-context'
import { getBasesLinks } from '@services/hooks/use-bases-links'
import { TBaseLink } from '@services/hooks/use-bases-links';

export function Navbar() {
  const location = useLocation();
  const { spinnerShowing } = useLoader();

  const [isOpenedNavbar, setIsOpenedNavbar] = useState(true);
  const [basesLinks, setBasesLinks] = useState<TBaseLink[]>([]);

  useEffect(() => {
    getBasesLinks().then((bases) => { setBasesLinks(bases) });
  }, []);

  return (
    <div>
      {spinnerShowing && (
        <div className='flex justify-center items-center w-[100vw] h-[100vh] absolute bg-[#000000e0] z-[50000] overflow-hidden'>
          <Loader color="#f1f2f6" type="dots" size="xl" />
        </div>
      )}
      <nav
        className={twMerge(
          'flex flex-col w-30 h-full p-4 border-r border-gray-300 bg-zinc-100 transition-width duration-300 ease-in-out relative',
          isOpenedNavbar && 'w-[17rem]',
        )}
      >
        <ActionIcon
          pos="absolute"
          right={-12}
          top={70}
          variant="outline"
          color="darkBlue.9"
          radius="xl"
          size="sm"
          aria-label="abrir e fechar o menu"
          onClick={() => setIsOpenedNavbar(prevState => !prevState)}
        >
          {isOpenedNavbar ? (
            <FiChevronLeft style={{ width: '80%', height: '80%' }} />
          ) : (
            <FiChevronRight style={{ width: '80%', height: '80%' }} />
          )}
        </ActionIcon>
        <Center>
          <Logo />
          <Text
            className={twMerge(
              'hidden text-4xl uppercase font-semibold ml-3',
              isOpenedNavbar && 'flex'
            )}
          >
            DFu
            <Text
              className={twMerge('text-4xl uppercase font-semibold')}
              component="span"
              c="lightBlue.4"
            >
              2
            </Text>
            re
          </Text>
        </Center>
        {!!basesLinks?.length && (
          <div className="flex-1 mt-8">
            <Stack justify="center" gap={0}>
              <NavbarLink
                label="Todas as Bases"
                icon={FaBuildingUser}
                link="/vehicles/all"
                active={location.pathname === '/vehicles/all'}
                isOpenedNavbar={isOpenedNavbar}
              />
              {basesLinks.map((baseLink) => {
                const chave = baseLink?.chave?.toLocaleLowerCase();

                return (
                  <NavbarLink
                    key={baseLink.id}
                    label={`${baseLink.chave} ${baseLink.nome}`}
                    icon={FaBuildingUser}
                    link={`/vehicles/${chave}`}
                    active={baseLink?.chave ? location.pathname === `/vehicles/${chave}` : null}
                    isOpenedNavbar={isOpenedNavbar}
                  />
                );
              })}
            </Stack>
          </div>
        )}
      </nav>
    </div>
  );
};
