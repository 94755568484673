import { http } from '@services/axios'
import { useQuery } from 'react-query'
import { TFilterVehicle } from './use-filter-vehicles'

export async function getByLastYesterday(filter: string): Promise<TFilterVehicle[]> {
  try {
    const { data: vehiclesByYesterday } = await http.get<TFilterVehicle[]>(
      `/Videos/ListarVeiculosOntem?filter=${filter}`,
    )
    return vehiclesByYesterday
  } catch (error) {
    console.log(error)
    return []
  }
}

export function useFilterVehiclesByYesterday(filter?: string) {
  const ft = filter || 'all'
  return useQuery({
    queryKey: [`vehicles-by-last-yesterday-${ft}`],
    queryFn: () => getByLastYesterday(ft),
    
  })
  
  //   [`vehicles-by-last-yesterday-${ft}`], () => getByLastYesterday(ft),
  //   {
  //     staleTime: 1000 * 60 * 60,
  //     enabled: true
  //   }
  // ) //60 min
}
